<template>
  <div class="GlobalTable">
    <div class="flex flex-jb list-top">
      <div class="flex">
        <img
          style="width: 20px; height: 20px; margin-right: 6px;"
          src="@/assets/images/iocn_ddlb@2x.png"
          alt
        />
        <span>收件记录</span>
      </div>
      <div>
        <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          :loading="Excelloading"
          @click="clickExcel"
          >导出Excel</el-button
        >
        <!-- <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="createdReceive"
          >创建收件</el-button
        > -->
      </div>
    </div>
    <GlobalTable
      ref="GlobalTable"
      class="sample-image"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="操作人" slot="creatorUser" align="center">
        <template slot-scope="{ row }">
          {{ row.creatorUserName }}-{{ row.creatorUserAccount }}
        </template>
      </el-table-column>
      <el-table-column label="异常出件" slot="anomalyReceiveNum" align="center">
        <template slot-scope="{ row }">
          <div
            class="text-btn"
            style="color:#ef696c;"
            v-if="row.anomalyReceiveNum"
            @click="seeAbnormalNum(row)"
          >
            {{ row.anomalyReceiveNum }}
          </div>
          <span v-else>{{ row.anomalyReceiveNum || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总收件" slot="allReceiveNum" align="center">
        <template slot-scope="{ row }">
          <div class="text-btn" style="color:#4ba0fc;" v-if="row.allReceiveNum" @click="seeAllReceiveNum(row)">
            {{ row.allReceiveNum }}
          </div>
          <span v-else>{{ row.allReceiveNum || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="繁星平台运单" slot="Starplatformwaybill" align="center">
        <template slot-scope="{ row }">
          <div style="color:#e3a043;" v-if="row.fxNum">
            {{ row.fxNum }}
          </div>
          <span v-else>{{ row.fxNum || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="非繁星平台运单" slot="noStarplatformwaybill" align="center">
        <template slot-scope="{ row }">
          <div style="color:#e3a043;" v-if="row.fxNotNum">
            {{ row.fxNotNum }}
          </div>
          <span v-else>{{ row.fxNotNum || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="operation"
        v-if="false"
        width="250px"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" round type="success" @click="checkPackage(row)"
            >核对快件</el-button
          >
          <el-button size="mini" round type="warning" @click="printBatchNo(row)"
            >打印批次号</el-button
          >
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog
      title="添加扫码收件"
      :visible.sync="scanAddShow"
      :close-on-click-modal="false"
      width="800px"
      @closed="scanAddClose"
    >
      <el-form :inline="true" size="small">
        <el-form-item label="扫描快递单号">
          <el-input
            ref="codeInput"
            v-model.trim="code"
            placeholder="请输入快递单号查询"
            clearable
            @keyup.enter.native="scanAdd"
          ></el-input>
          <!-- form表单只有一个input的时候会出现回车刷新页面的问题 所以另外加一个input并隐藏 -->
          <el-input style="display:none;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="scanAdd">确认添加</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-bottom:10px;font-size:16px;color:#333">已添加<span style="color:#2384fc">{{dialogTableData.length}}个</span>，最多可添加60个快递单</div>
      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns2"
        :data="
          dialogTableData.slice(
            (page2.pageNum - 1) * page2.pageSize,
            page2.pageNum * page2.pageSize
          )
        "
        :currentPage="page2.pageNum"
        :total="dialogTableData.length"
        :pageSize="page2.pageSize"
        @handleCurrentChange="dialogCurrentChange"
      >
        <el-table-column label="备注" slot="remark" width="120" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.remark"
              placement="top"
            >
              <div class="Remarks">{{ row.remark }}
              </div>
            </el-tooltip>
            <div class="tableBtn" @click="setRemark(row)">
              {{ row.remark ? "修改" : "添加" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          slot="operation"
          width="80"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button size="mini" round type="danger" @click="delOne(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            () => {
              dialogTableData.length
                ? (cancelShow = true)
                : (scanAddShow = false);
            }
          "
          >取消操作</el-button
        >
        <el-button type="primary" @click.native="sureAdd">确定收件</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="remarks_dialog"
      :title="remarksTitle"
      :visible.sync="remarksShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="remarksClosed"
    >
      <div class="flex" style="margin-bottom: 10px">
        <span>快捷备注：</span>
        <div class="fast_note_list flex">
          <div class="note_item flex flex-jc" v-for="(item,index) in fastNoteList2" :key="index" @click="selectNote(item,'add')">{{item}}</div>
        </div>
      </div>
      <el-input
        maxlength="50"
        type="textarea"
        :rows="3"
        placeholder="请输入备注内容,最多50字"
        show-word-limit
        v-model="masterNote"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksShow = false">取消</el-button>
        <el-button type="primary" @click.native="remarksSure"
          >确认添加</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除提示"
      :visible.sync="delDialogShow"
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="enable-tip">
        <div class="tip-sure">
          是否确认删除该条数据？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="delDialogShow = false">取消</el-button>
        <el-button type="primary" @click.native="delDialogSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="取消操作提示"
      :visible.sync="cancelShow"
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="enable-tip">
        <div class="tip-sure">
          是否确认取消此次操作？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cancelShow = false">取消</el-button>
        <el-button type="primary" @click.native="cancelSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加提示"
      :visible.sync="addTipShow"
      :close-on-click-modal="false"
      width="400px"
    >
      <div>
        <span style="color:#2384fc">{{repeatNum[0]}}{{repeatNum[1]?'、' + repeatNum[1]:''}}</span>
        <span>{{repeatNum.length>2?'...':''}} <span style="color:red;">{{repeatNum.length}}</span>个单号已收件，请删除后重新收件。</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="addTipShow = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="'查看收件快递——'+ allRowObj.receiveBatchNumber"
      :visible.sync="viewRecipientsShow"
      :close-on-click-modal="false"
      width="1200px"
      @closed="viewRecipientsClose"
    >
      <div class="flex flex-jb mb-10" style="font-size:16px">
        <span>收件批次号：{{allRowObj.receiveBatchNumber}}</span>
        <span>操作人：{{ allRowObj.creatorUserName }}-{{ allRowObj.creatorUserAccount }}</span>
        <span>包裹数量：{{page3.total}}</span>
      </div>
      <el-form :inline="true" size="small">
        <el-form-item label="快递单号">
          <el-input
            v-model.trim="allCode"
            placeholder="请输入完整快递单号查询"
            clearable
          ></el-input>
          <!-- form表单只有一个input的时候会出现回车刷新页面的问题 所以另外加一个input并隐藏 -->
          <el-input style="display:none;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="allDialogCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
      <GlobalTable
        ref="GlobalTable"
        v-loading="allLoading"
        :columns="tableColumns3"
        :data="dialogTableData3"
        :currentPage="page3.pageNum"
        :total="page3.total"
        :pageSize="page3.pageSize"
        @handleCurrentChange="allDialogCurrentChange"
      >
        <el-table-column label="所属快递" slot="courierType" width="80px" align="center">
          <template slot-scope="{ row }">
            <span>{{row.courierType == 'JD'?'京东':'顺丰'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否为繁星平台订单" slot="startOrder"  align="center">
          <template slot-scope="{ row }">
            <span>{{row.isFx?'是':'否'}}</span>
            <p v-if="row.isFx" class="text-down" @click="handleBtn(row)">查看详情</p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="收货旧机" slot="oldmachineNum"  align="center">
          <template slot-scope="{ row }">
            <span>{{row.oldmachineNum }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="发货拍照" slot="oldmachineImg"  align="center">
          <template slot-scope="{ row }">
            <div
                  class="images-box"
                  v-if="row.images && row.images.length > 0"
                >
                  <img :src="row.images[0].img" alt="" />
                  <span @click="openImgBox(row)" class="text-down"
                    >共{{ row.images.length }}张</span
                  >
                </div>
                <div v-else>暂无图片</div>
          </template>
        </el-table-column> -->
        <el-table-column label="系统关联订单" slot="associatedOrderNum" width="100px" align="center">
          <template slot-scope="{ row }">
          <div class="text-btn" style="color:#2384fc;" v-if="row.associatedOrderNum" @click="seeAssociatedOrderNum(row)">
            {{ row.associatedOrderNum }}
          </div>
          <span v-else>{{ row.associatedOrderNum || 0 }}</span>
        </template>
        </el-table-column>
        <el-table-column label="备注" slot="remark" width="120" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              v-if="row.remark"
              class="item"
              effect="dark"
              :content="row.remark"
              placement="top"
            >
              <div class="Remarks">{{ row.remark }}
              </div>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          slot="operation"
          v-if="false"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.isAnomaly">-已标记异常出件-</span>
            <el-button v-else size="mini" round type="danger" @click="abnormalOne(row)"
              >异常出件</el-button
            >
          </template>
        </el-table-column>
      </GlobalTable>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="viewRecipientsShow = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="abnormal_dialog"
      title="标记异常出件"
      :visible.sync="abnormalShow"
      :close-on-click-modal="false"
      width="600px"
      @closed="abnormalClosed"
    >
      <div class="mb-20" style="text-align:center;font-size:16px">是否确认标记已收件单号：<span style="color:#2384fc;">{{abnormalRowObj.courierNumber}}</span>为异常出件？</div>
      <div class="flex" style="margin-bottom: 10px">
        <span>选择异常出件原因：</span>
        <div class="fast_note_list flex">
          <div class="note_item flex flex-jc" v-for="(item,index) in fastNoteList" :key="index" @click="selectNote(item)">{{item}}</div>
        </div>
      </div>
      <el-input
        maxlength="50"
        type="textarea"
        :rows="3"
        placeholder="请输入具体异常原因"
        show-word-limit
        v-model="anomalyCause"
      >
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="abnormalShow = false">取消</el-button>
        <el-button type="primary" @click.native="abnormalSure" :loading="butLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <DialogTbale
      :show="abnormalListShow"
      :title="'查看异常出件——'+ allRowObj.receiveBatchNumber"
      :loading="abnormalListLoading"
      :columns="abnormalListColumns"
      :data="abnormalList"
      :currentPage="page4.pageNum"
      :total="page4.total"
      :pageSize="page4.pageSize"
      :width="900"
      @onClose="abnormalListClose"
      @handleCurrentChange="abnormalListCurrentChangeDialog"
    >
      <el-table-column label="操作人" slot="creatorUser" align="center">
        <template slot-scope="{ row }">
          {{ row.anomalyUserName }}-{{ row.anomalyUserAccount }}
        </template>
      </el-table-column>
      <el-table-column label="异常原因" slot="anomalyCause" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              v-if="row.anomalyCause"
              class="item"
              effect="dark"
              :content="row.anomalyCause"
              placement="top"
            >
              <div class="Remarks">{{ row.anomalyCause }}
              </div>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
    </DialogTbale>
    <el-dialog
      :title="'关联订单——' + orderRow.courierNumber"
      :visible.sync="orderListShow"
      :close-on-click-modal="false"
      width="1000px"
      @closed="orderListClosed"
    >
      <div style="overflow-y: auto; max-height: 600px">
        <div class="order-list" :class="{'order-list-01':item.state=='01'||item.state=='02'||item.state=='03'||item.state=='04'||item.state=='05','order-list-02':item.state=='00'||item.state=='10'||item.state=='20'||item.state=='30'}"
          v-for="(item, index) in orderList"
          :key="index">
          <el-table :data="[item]" border id="order">
            <el-table-column prop="orderNo" label="订单号" align="center">
              <template slot-scope="{ row }">
                <div>{{row.orderNo}}</div>
                <el-tag
                 :key="12"
                 type="danger"
                  v-if="row.state === '01'||row.state === '03'"
                   size="small">
                  <span>未收货</span>
                </el-tag>
                <el-tag
                :key="67"
                  v-else
                  :type="row.state=='02'||row.state=='04'||row.state=='05'?'':'info'" size="small">
                  <span v-if="row.state === '00'">待确认</span>
                  <span v-else-if="row.state === '01'">已确认</span>
                  <span v-else-if="row.state === '02'">待支付</span>
                  <span v-else-if="row.state === '03'">已绑码</span>
                  <span v-else-if="row.state === '04'">已收货</span>
                  <span v-else-if="row.state === '05'">降价收货</span>
                  <span v-else-if="row.state === '10'">已取消</span>
                  <span v-else-if="row.state === '20'">已作废</span>
                  <span v-else-if="row.state === '30'">已退回</span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="images" label="旧机图片" align="center">
              <template slot-scope="{ row }">
                <div
                  class="images-box"
                  v-if="row.images && row.images.length > 0"
                >
                  <el-image fit="contain" style="width: 50px; height: 50px" :src="row.images[0].smallImg || row.images[0].img" :preview-src-list="row.imgList" />
                </div>
                <div v-else>暂无图片</div>
              </template>
            </el-table-column>
            <el-table-column prop="phoneName" label="旧机名称" align="center">
              <template slot-scope="{ row }">
                <span>{{row.phoneName || '--'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="barCode" label="背贴条码" align="center">
              <template slot-scope="{ row }">
                <span>{{row.barCode || '--'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="imei" label="旧机串号" align="center">
              <template slot-scope="{ row }">
                <span>{{row.imei || '--'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="expressNo" label="快递信息" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.expressNo" style="color:#FF0024;">{{row.expressNo}}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="ackTime" label="成交时间" align="center">
              <template slot-scope="{ row }">
                <span>{{row.ackTime || '--'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="companyName" label="订单归属商家" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  v-if="row.companyName"
                  class="item"
                  effect="dark"
                  :content="row.companyName"
                  placement="top"
                >
                  <div class="Remarks2">{{ row.companyName }}
                  </div>
                </el-tooltip>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="成交回收商" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  v-if="row.merchantName&&row.merchantNameIsSelf"
                  class="item"
                  effect="dark"
                  :content="row.merchantName"
                  placement="top"
                >
                  <div class="Remarks2">{{ row.merchantName }}
                  </div>
                </el-tooltip>
                <div class="Remarks2" style="color:red"  v-if="row.merchantName&&!row.merchantNameIsSelf">非您商户,无法查看
                </div>
                <span v-if="!row.merchantName">--</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="height:20px"></div>
    </el-dialog>
    <el-dialog
      :title="'核对快件——'+allRowObj.receiveBatchNumber"
      :visible.sync="checkTableShow"
      :close-on-click-modal="false"
      width="800px"
      @closed="checkTableClose"
    >
      <el-form :inline="true" size="small">
        <el-form-item label="扫描快递单号">
          <el-input
            ref="checkInput"
            v-model.trim="code"
            placeholder="请输入快递单号查询"
            clearable
            @keyup.enter.native="scanCheck"
          ></el-input>
          <!-- form表单只有一个input的时候会出现回车刷新页面的问题 所以另外加一个input并隐藏 -->
          <el-input style="display:none;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="scanCheck">查询</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-bottom:10px;font-size:16px;color:#f80000">以下快递未在该批次号</div>
      <GlobalTable
        ref="GlobalTable"
        :columns="tableColumns4"
        :data="
          checkTableData.slice(
            (page5.pageNum - 1) * page5.pageSize,
            page5.pageNum * page5.pageSize
          )
        "
        :currentPage="page5.pageNum"
        :total="checkTableData.length"
        :pageSize="page5.pageSize"
        @handleCurrentChange="checkTableCurrentChange"
      >
      </GlobalTable>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="checkTableShow = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看发货拍照照片 -->
    <el-dialog
      :close-on-click-modal="false"
      :width="`${800}px`"
      title="查看发货拍照照片"
      :visible="imgShow"
      @close="imgClose"
      top="5%"
    >
      <div class="img-box">
        <div class="img-tips-box">
          <div
            class="img-tips"
            :class="imgShowOnly ? 'img-tips-choose' : 'img-tips-default'"
          >
            旧机照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList[imgIndex].length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList[imgIndex].length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList[imgIndex]">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item
                  v-for="item in DialogImgList[imgIndex]"
                  :key="item.imgNo"
                >
                  <div class="sec_img">
                    <img :src="item.img" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
        </div>
      </div>
    </el-dialog>
     <!--物流详情-->
     <el-dialog
        class="acc_dialog"
        title="物流详情"
        :visible.sync="Logisticsdetails"
        :close-on-click-modal="false"
        width="810px"
    >
      <div v-loading="loading2">
        <div class="fzs18 b25">快递单号：{{ Logisticsarray.expressNo }}</div>

        <div
            style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
          "
        >
          <div class="one">
            <div class="fzs18 b03">{{ Logisticsarray.senderCompanyName }}</div>
            <div class="fzs14">{{ Logisticsarray.senderStoreName }}</div>
          </div>

          <div class="one_img">
            <img
                src="../../../assets/images/jiantouleft.png"
                style="38px;11px;"
                alt=""
            />
          </div>

          <div class="one">
            <div class="fzs18 b03">
              {{ Logisticsarray.receiverMerchantName }}
            </div>
            <!-- <div class="fzs14">太升南路十号店</div> -->
          </div>
        </div>

        <div
            style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <div class="two">
            <div class="twoinfo">寄件人信息</div>
            <div class="twophone">
              {{ Logisticsarray.senderName }}-{{ Logisticsarray.senderMobile }}
            </div>
            <div>{{ Logisticsarray.senderAddress + Logisticsarray.senderAddressDetail }}</div>
          </div>
          <div class="two">
            <div class="twoinfo">收件人信息</div>
            <div class="twophone">
              {{ Logisticsarray.receiverName }}-{{
                Logisticsarray.receiverMobile
              }}
            </div>
            <div>{{ Logisticsarray.receiverAddress + Logisticsarray.receiverAddressDetail }}</div>
          </div>
        </div>

        <div class="loacinfo">物流信息</div>

        <div class="three">
          <el-timeline
              v-if="
              this.Logisticsarray.logistics &&
              this.Logisticsarray.logistics.length
            "
          >
            <el-timeline-item
                v-for="(activity, index) in Logisticsarray.logistics"
                :key="index"
                :timestamp="activity.opeRemark"
            >
              {{ activity.opeTime }}
            </el-timeline-item>
          </el-timeline>
          <el-empty
              v-else
              style="margin-top: -40px"
              description="暂无物流信息"
          ></el-empty>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Logisticsdetails = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {},
  data() {
    return {
      // 物流详情
      Logisticsdetails: false,
      Logisticsarray: {},
      loading2: false,
     // 物流详情end
     //查看发货拍照
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [[], []],
      imgShowIndex: 0,
      imgIndex: 0,
      //查看发货拍照end
      loading: false,
      seachDataList: [],
      scanAddShow: false,
      butLoading: false,
      rowObj: {},
      repeatNum: [],
      code: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      page2: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      page3: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      page4: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      page5: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableColumns: [
        { label: "收件批次号", prop: "receiveBatchNumber" },
        { slotName: "creatorUser" },
        { label: "收件时间", prop: "createTime" },
        { label: "京东收件", prop: "jdReceiveNum" },
        { label: "顺丰收件", prop: "sfReceiveNum" },
        { slotName: "anomalyReceiveNum" },
        { slotName: "allReceiveNum" },
        { slotName: "Starplatformwaybill" },
        { slotName: "noStarplatformwaybill" },
        // { slotName: "operation" },
      ],
      dialogTableData: [],
      tableColumns2: [
        { label: "子快递单号", prop: "courierNumber" },
        { label: "快递单号", prop: "courierNumberParent" },
        { label: "添加时间", prop: "scanTime" },
        { slotName: "remark" },
        { slotName: "operation" },
      ],
      // 查看总收件快递弹框
      allRowObj: {},
      allLoading: false,
      viewRecipientsShow: false,
      dialogTableData3: [],
      tableColumns3: [
        { label: "子快递单号", prop: "courierNumber" },
        { label: "快递单号", prop: "courierNumberParent" },
        { slotName: "courierType" },
        { label: "扫码时间", prop: "scanTime" },
        { slotName: "startOrder" },
        {slotName:"oldmachineNum"},
        {slotName:"oldmachineImg"},
        { slotName: "associatedOrderNum" },
        { slotName: "remark" },
        { slotName: "operation" },
      ],
      allCode: "",
      // 异常出件列表弹框
      abnormalListShow: false,
      abnormalListLoading: false,
      abnormalList: [],
      fastNoteList: ['快递送错包裹','重复收件'],
      fastNoteList2: ['包裹破损','空包裹'],
      abnormalListColumns: [
        { label: "子快递单号", prop: "courierNumber" },
        { label: "快递单号", prop: "courierNumberParent" },
        { slotName: "creatorUser" },
        { label: "操作时间", prop: "anomalyTime" },
        { slotName: "anomalyCause" }
      ],
      // 核对快件弹框
      checkTableShow: false,
      checkTableData: [],
      tableColumns4: [
        { label: "子快递单号", prop: "courierNumber" },
        { label: "快递单号", prop: "courierNumberParent" },
        { label: "扫码时间", prop: "scanTime" },
      ],
      // 关联订单弹框
      orderListShow: false,
      orderList: [],
      orderRow: {},
      // 备注弹框
      remarksShow: false,
      remarksTitle: "",
      masterNote: "",
      scanOnRow: {},
      // 删除提示弹框
      delDialogShow: false,
      // 取消操作提示
      cancelShow: false,
      // 添加提示弹框
      addTipShow: false,
      // 异常收件弹框
      abnormalRowObj: {},
      abnormalShow: false,
      anomalyCause: "",
      Excelloading:false,
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
     // 弹出弹框
     handleBtn(row) {
        this.loading2 = true;
        this.Logisticsdetails = true;
        _api.merchantlogisticsInfo({expressNo: row.courierNumber,merchantId:this.$route.query.merchantId,}).then((res) => {
          console.log(res);
          this.Logisticsarray = res.data;
          this.loading2 = false;
        });
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      console.log(this.DialogImgList);
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        receiveBatchNumber: this.SeachParams.receiveBatchNumber || "",
        courierNumberParent: this.SeachParams.courierNumberParent || "",
        creatorUserId: this.SeachParams.creatorUserId || "",
        endTime: this.SeachParams.endTime || "",
        startTime: this.SeachParams.startTime || "",
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        merchantId:this.$route.query.merchantId,
      };
      this.loading = true;
      _api.getReceiveList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    clickExcel() {
      const params = {
        merchantId:this.$route.query.merchantId,
        ...this.SeachParams,
      };
      this.Excelloading = true;
      _api
        .receiveExcel(params)
        .then((res) => {
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              let start = moment(Number(params.startTime)).format("YYYY.MM.DD");
              let end = moment(Number(params.endTime)).format("YYYY.MM.DD");
              const fileName = `【导出收件快递明细】${start || ""}${
                end ? "-" + end : ""
              }.xlsx`;
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.Excelloading = false;
        })
        .catch((err) => {
          this.Excelloading = false;
          console.log(err);
        });
    },
    // 核对快件按钮
    checkPackage(row) {
      this.allRowObj = row
      this.checkTableShow = true
      this.$nextTick(()=> {
          // $refs引用getNameFocus
        this.$refs.checkInput.focus();
      });
    },
    // 打印批次号按钮
    printBatchNo(row) {
      this.CreatePrintPage(row);
      setTimeout(()=>{
          // this.LODOP.PREVIEW(); //预览打印
          this.LODOP.PRINT(); // 直接打印
          // this.LODOP.PRINT_DESIGN();//设计模式打印
        },300)
    },
    CreatePrintPage(data) {
      	// this.LODOP = getLodop();
        // // this.LODOP.PRINT_INIT("条形码");
        // this.LODOP.SET_PRINT_PAGESIZE(1, '40mm', '30mm', "")
        // // this.LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", 'Full-Width'); 
        // // this.LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
        // // this.LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);DMPQ2ABDG5W0
        // // this.LODOP.SET_PRINT_STYLE("FontName",'微软雅黑');
        // //纯文本字体间距
        // // this.LODOP.SET_PRINT_STYLE("LetterSpacing", 35);
        // // //字号
        // this.LODOP.SET_PRINT_STYLE("FontSize", 8);
        // this.LODOP.ADD_PRINT_TEXT(10, 0, '40mm', 0, '批次号:'+ data.receiveBatchNumber)
        // this.LODOP.ADD_PRINT_BARCODE(25,0,'60mm','15mm',"qrcode", data.receiveBatchNumber);
        // // this.LODOP.ADD_PRINT_TEXT(75, 0, '40mm', 0, "打印日期:" + moment(new Date()).format("YYYY-MM-DD"))
        // this.LODOP.ADD_PRINT_TEXT(75, 0, '60mm', 0, "收件:" + data.createTime)
        // this.LODOP.ADD_PRINT_TEXT(90, 0, '40mm', 0, "操作人:" + data.creatorUserName + '-' + data.creatorUserAccount)
    },
    // 创建收件
    createdReceive() {
      this.scanAddShow = true;
      this.$nextTick(()=> {
        // $refs引用getNameFocus
        this.$refs.codeInput.focus();
      });
    },
    // 创建快件弹框关闭
    scanAddClose() {
      this.dialogTableData = [];
      this.code = "";
    },
    scanAdd() {
      if(this.dialogTableData.length == 60){
        this.$message.error("最多只能添加60个快递单");
        this.code = ""
        return;
      }
      if (!this.code) {
        this.$message.error("请扫码或输入快递单号");
        return;
      }
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (this.code.length < 15 || reg.test(this.code)) {
        this.$message.error("快递单号有误");
        return;
      }
      let flag = false;
      this.dialogTableData.forEach((item) => {
        if (item.courierNumber == this.code) {
          flag = true;
        }
      });
      if (flag) {
        this.$message.error("单号已存在，请勿重复扫码");
        this.code = "";
        return;
      }
      let obj = {
        courierNumber: this.code,
        courierNumberParent: this.code.substr(0, 15),
        scanTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        remark: "",
      };
      this.dialogTableData.unshift(obj);
      this.$message.success("操作成功，已自动添加单号！");
      this.code = "";
    },
    // 扫码添加列表翻页
    dialogCurrentChange(val) {
      if (val) {
        this.page2.pageNum = val;
      }
    },
    // 核对表格翻页
    checkTableCurrentChange(val) {
      if (val) {
        this.page5.pageNum = val;
      }
    },
    // 确认添加快件
    sureAdd() {
      let expressVos = [];
      this.dialogTableData.forEach((item) => {
        expressVos.push({
          courierNumber: item.courierNumber,
          courierNumberParent: item.courierNumberParent,
          remark: item.remark,
          scanTime: moment(item.scanTime).format('x'),
        });
      });
      _api.addReceive({merchantId:this.$route.query.merchantId,expressVos}).then((res) => {
        if (res.code == 1) {
          if(res.data && res.data.length){
            this.addTipShow = true
            this.repeatNum = res.data
          }else{
            this.scanAddShow = false
            this.$message.success("操作成功");
            this.handleCurrentChange(1)
          }
        }
      });
    },
    // 备注设置
    setRemark(row) {
      this.scanOnRow = row;
      this.remarksTitle = row.remark ? "修改备注" : "添加备注";
      this.masterNote = row.remark;
      this.remarksShow = true;
    },
    // 添加/修改备注弹框关闭
    remarksClosed() {
      this.masterNote = "";
    },
    // 确认添加/修改备注
    remarksSure() {
      this.dialogTableData.forEach((item) => {
        if (item.courierNumber == this.scanOnRow.courierNumber) {
          item.remark = this.masterNote;
        }
      });
      this.remarksShow = false;
    },
    // 删除当前行
    delOne(row) {
      this.scanOnRow = row;
      this.delDialogShow = true;
    },
    // 删除弹框确认操作
    delDialogSubmit() {
      this.dialogTableData.forEach((item, index) => {
        if (item.courierNumber == this.scanOnRow.courierNumber) {
          this.dialogTableData.splice(index, 1);
        }
      });
      this.delDialogShow = false;
    },
    // 取消操作弹框确认
    cancelSubmit() {
      this.cancelShow = false;
      this.scanAddShow = false;
    },
    // 表格总收件点击
    seeAllReceiveNum(row) {
      this.allRowObj = row
      this.allDialogCurrentChange(1)
      this.viewRecipientsShow = true
    },
    // 总收件快递弹框关闭
    viewRecipientsClose() {
      this.allRowObj = {}
      this.allCode = ""
      this.page3.pageNum = 1
    },
    // 总收件快递弹框翻页
    allDialogCurrentChange(val) {
      if (val) {
        this.page3.pageNum = val;
      }
      console.log(this.allRowObj);
      const baseRequest = {
        receiveBatchNumber: this.allRowObj.receiveBatchNumber || "",
        courierNumberParent: this.allCode || "",
        merchantId:this.$route.query.merchantId,
        pageNum: this.page3.pageNum,
        pageSize: this.page3.pageSize,
      };
      this.allLoading = true;
      _api.allReceiveList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.dialogTableData3 = res.data.records;
          this.page3.total = res.data.total;
          this.page3.pageNum = res.data.current;
          this.allLoading = false;
        }
      });
    },
    // 异常出件按钮
    abnormalOne(row) {
      this.abnormalRowObj = row
      this.abnormalShow = true
    },
     // 异常出件弹框关闭
    abnormalClosed() {
      this.abnormalRowObj = {}
      this.anomalyCause = ""
    },
    // 异常出件弹框确定
    abnormalSure() {
      let params = {
        courierNumber: this.abnormalRowObj.courierNumber,
        receiveBatchNumber: this.allRowObj.receiveBatchNumber,
        anomalyCause: this.anomalyCause,
        merchantId:this.$route.query.merchantId,
      }
      this.butLoading = true
      _api.courierNumber(params).then(res => {
        if(res.code == 1){
          this.$message.success("操作成功");
          this.abnormalShow = false
          this.allDialogCurrentChange()
          this.handleCurrentChange()
        }
        this.butLoading = false
      }).catch(()=>{
        this.butLoading = false
      })
    },
    // 表格异常出件点击
    seeAbnormalNum(row) {
      // this.allRowObj = row
      // this.abnormalListCurrentChangeDialog(1)
      // this.abnormalListShow = true
    },
    // 总收件快递弹框翻页
    abnormalListCurrentChangeDialog(val) {
      if (val) {
        this.page4.pageNum = val;
      }
      console.log(this.allRowObj);
      const baseRequest = {
        receiveBatchNumber: this.allRowObj.receiveBatchNumber || "",
        isAnomaly: true,
        pageNum: this.page4.pageNum,
        pageSize: this.page4.pageSize,
        merchantId:this.$route.query.merchantId,
      };
      this.abnormalListLoading = true;
      _api.allReceiveList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.abnormalList = res.data.records;
          this.page4.total = res.data.total;
          this.page4.pageNum = res.data.current;
          this.abnormalListLoading = false;
        }
      });
    },
    abnormalListClose(){
      this.abnormalListShow = false
      this.page4.pageNum = 1
    },
    // 系统关联订单号点击
    seeAssociatedOrderNum(row) {
      this.orderRow = row
      this.orderListShow = true
      this.getOrderList()
    },
    // 关联订单弹框关闭
    orderListClosed() {
      this.orderRow = {}
    },
    getOrderList() {
      _api.findOrder({expressNo:this.orderRow.courierNumber,merchantId:this.$route.query.merchantId}).then(res => {
        if(res.code == 1){
          this.orderList = res.data
          this.orderList.forEach(item => {
            item.imgList = []
            item.images.forEach(ele => {
              item.imgList.push(ele.img)
            })
          })
        }
      })
    },
    scanCheck() {
      if (!this.code) {
        this.$message.error("请扫码或输入快递单号");
        return;
      }
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      if (this.code.length < 15 || reg.test(this.code)) {
        this.$message.error("快递单号有误");
        return;
      }
      let flag = false;
      this.checkTableData.forEach((item) => {
        if (item.courierNumber == this.code) {
          flag = true;
        }
      });
      if (flag) {
        this.$message.error("单号已存在，请勿重复扫码");
        this.code = "";
        return;
      }
      let params = {
        courierNumber: this.code,
        receiveBatchNumber: this.allRowObj.receiveBatchNumber,
        merchantId:this.$route.query.merchantId,
      }
      _api.checkCourier(params).then(res => {
        if(res.code == 1){
          console.log(res);
          if(!res.data){
            let obj = {
              courierNumber: this.code,
              courierNumberParent: this.code.substr(0, 15),
              scanTime: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            console.log(obj);
            this.checkTableData.unshift(obj);
          }
        }
        this.code = "";
      })
    },
    // 核对弹框关闭
    checkTableClose() {
      this.code = ""
      this.allRowObj = {}
      this.checkTableData = []
    },
    // 点击快捷备注
    selectNote(item,type){
      if(type == 'add'){
        this.masterNote = this.masterNote?this.masterNote + '、' + item:this.masterNote + item
        this.masterNote = this.masterNote.substr(0,50)
      }else{
        this.anomalyCause = this.anomalyCause?this.anomalyCause + '、' + item:this.anomalyCause + item
        this.anomalyCause = this.anomalyCause.substr(0,50)
      }
      
    },
    
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }
  .text-down {
    color: #0981ff;
    cursor: pointer;
    text-decoration: underline;
  }
  .images-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .images-box > img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    cursor: pointer;
    // margin-bottom: 10px;
  }
  .images-box > span {
    color: #0981ff;
    cursor: pointer;
  }
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; //控制行数
    overflow: hidden;
  }
  // 备注说明
  .Remarks2 {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .text-btn {
    text-decoration: underline;
    cursor: pointer;
  }
  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }
  .order-list:not(:first-child){
    margin-top: 20px;
  }
  .order-list-01{
    /deep/.el-table .el-table__header th{
      background: #D5E2FE !important;
      border-right: 1px solid #E4ECFD !important;
    }
  }
  .order-list-02{
    /deep/.el-table .el-table__header th{
      background: #EBEBEB !important;
      border-right: 1px solid #EBEBEB !important;
    }
  }
  .fast_note_list{
    .note_item{
      padding: 0 15px;
      height: 32px;
      background: #F9FBFD;
      border: 1px solid #D9E6FF;
      border-radius: 4px;
      margin-right: 20px;
      font-size: 14px;
      color: #333333;
      line-height: 32px;
      cursor: pointer;
    }
  }
  .list-top {
    margin-bottom: 20px;
    color: #333333;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-je {
    display: flex;
    justify-content: flex-end;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .mb-20{
    margin-bottom: 20px;
  }
  .mb-10{
    margin-bottom: 10px;
  }
  /deep/.el-img-upload .el-upload {
    background-color: #f5f6fc;
  }
}
.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-box > img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.images-box > span {
  color: #0981ff;
  cursor: pointer;
}
.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}
.one {
  flex: 1;
  text-align: center;
}

.one_img {
  width: 38px;
  height: 11px;
}

.fzs18 {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
}

.b25 {
  margin-bottom: 15px;
}

.fzs14 {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #666666;
}

.b03 {
  margin-bottom: 3px;
}

.two {
  max-height: 124px;
  background: #f5f6fa;
  border-radius: 6px;
  width: 360px;
  padding: 10px 13px 15px 13px;
  box-sizing: border-box;
}

.twoinfo {
  font-size: 16px;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  color: #0981ff;
  margin-bottom: 6px;
}

.twophone {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-bottom: 6px;
}

.loacinfo {
  font-size: 16px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-top: 24px;
}

.three {
  min-height: 100px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 16px;
  max-height: 200px;
  overflow: auto;
  padding: 20px 20px;
  box-sizing: border-box;
  padding-bottom: 0;
}

.three::-webkit-scrollbar {
  width: 3px;
  // display: none;
}

/*定义滚动条轨道*/
.three::-webkit-scrollbar-track {
  border-radius: 10px;
  // display: none;
}

/*定义滑块*/
.three::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(27, 140, 236, 0.5);
  display: none;
}
</style>
