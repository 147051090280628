<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="85"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item class="el_from" label="收件时间" slot="entryTime">
        <el-date-picker
          v-model="entryTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="操作人" slot="creatorUserId">
        <el-select
          v-model="creatorUserId"
          placeholder="请选择操作人"
          filterable
          clearable
        >
          <el-option
            v-for="item in operatorList"
            :key="item.id"
            :label="item.staffName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";

export default {
  name: "Form",
  data() {
    return {
      initData: null,
      formItemList: [
        {
          key: "receiveBatchNumber",
          type: "input",
          labelName: "收件批次号",
          placeholder: "请输入收件批次号",
          lableWidth: "115px",
        },
        {
          key: "courierNumberParent",
          type: "input",
          labelName: "快递单号",
          placeholder: "请输入快递单号",
        },
        { slotName: "entryTime" },
        { slotName: "creatorUserId" },
      ],
      entryTime: null,
      SeachParams: {},
      formRules: {},
      creatorUserId: "",
      operatorList: [],
    };
  },
  created() {
    let startTime = moment()
      .day(moment().day() - 30)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.entryTime = [startTime, endTime];
    this.operatorSelect();
    this.handleConfirm();
  },
  methods: {
    handleConfirm(data,cd) {
      let startTime = "";
      let endTime = "";
      if (this.entryTime === null) {
        (startTime = ""), (endTime = "");
      } else {
        (startTime = moment(this.entryTime[0]).format("x")),
          (endTime = moment(this.entryTime[1]).format("x"));
      }
      console.log(data);
      const SeachParams = {
        receiveBatchNumber:
          data && data.receiveBatchNumber ? data.receiveBatchNumber : "",
        courierNumberParent:
          data && data.courierNumberParent ? data.courierNumberParent : "",
        creatorUserId: this.creatorUserId,
        startTime,
        endTime,
        pageNum: 1,
        pageSize: 10,
        merchantId:this.$route.query.merchantId,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 获取操作人下拉
    operatorSelect() {
      _api
        .operatorSelect({
          pageNum: 1,
          pageSize: 999,
          merchantId:this.$route.query.merchantId
        })
        .then((res) => {
          if (res.code == 1) {
            this.operatorList = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
