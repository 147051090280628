<template>
  <div class="class-list">
    <GlobalInfoBar
      title="收件管理"
      content="记录繁星中转仓收到的快递包裹"
    >
    </GlobalInfoBar>
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From />
      </div>
      <Table />
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
